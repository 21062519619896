
import InfoCarousel from "./InfoCarousel";

export const Home = () => {
    return (
        <>


<InfoCarousel/>
        </>

    );
}